import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation";

const splashScreen = {
  enabled: true,
  animation: splashAnimation,
  duration: 2000
};

const illustration = {
  animated: true
};

const greeting = {
  username: "Mihir Panchal",
  title: "Hi all, I'm Mihir Panchal",
  subTitle: emoji(
    "I'm Mihir Panchal, a passionate Computer Engineering student with expertise in AI, full-stack development, and research, currently building impactful solutions with cutting-edge technologies."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1ofFdKF_mqscH8WvXkSObnVvC9kK7Ldlu/view?usp=sharing",
  displayGreeting: true
};

const socialMediaLinks = {
  github: "https://github.com/MihirRajeshPanchal",
  linkedin: "https://www.linkedin.com/in/mihirpanchal54/",
  gmail: "mihirpanchal5400@gmail.com",
  gitlab: "https://gitlab.com/MihirRajeshPanchal",
  medium: "https://medium.com/@mihirpanchal5400",
  stackoverflow: "https://stackoverflow.com/users/19471646/mihir-panchal",
  kaggle: "https://www.kaggle.com/mihirrajeshpanchal",
  instagram: "https://www.instagram.com/mihir_panchal_16/",
  display: true
};

const skillsSection = {
  title: "What I do",
  subTitle: "🔥 Full Stack Developer Obsessed with Mastering Every Tech Stack",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji(
      "⚡ Integrate applications using Artificial Intelligence and Machine Learning"
    ),
    emoji("⚡ Research and Development"),
    emoji("⚡ Creating application backend in Django, Fastapi & Flask")
  ],

  softwareSkills: [
    "anaconda",
    "androidstudio",
    "aws",
    "blender",
    "bootstrap",
    "c",
    "cs",
    "css",
    "dart",
    "devto",
    "django",
    "docker",
    "dynamodb",
    "elasticsearch",
    "fastapi",
    "figma",
    "firebase",
    "flask",
    "flutter",
    "gcp",
    "git",
    "github",
    "githubactions",
    "html",
    "ai",
    "js",
    "linux",
    "mongodb",
    "mysql",
    "netlify",
    "nextjs",
    "nodejs",
    "npm",
    "opencv",
    "postgres",
    "postman",
    "prisma",
    "py",
    "pytorch",
    "react",
    "redis",
    "replit",
    "sqlite",
    "sklearn",
    "tailwind",
    "tensorflow",
    "terraform",
    "threejs",
    "ubuntu",
    "unity",
    "vercel",
    "vite",
    "vscode"
  ],

  display: true
};

const educationInfo = {
  display: true,
  schools: [
    {
      schoolName: "Dwarkadas J. Sanghvi College of Engineering",
      logo: require("./assets/images/djsce.png"),
      subHeader: "Bachelor of Technology in Computer Engineering",
      duration: "June 2023 - June 2026",
      desc: "CGPA : 8.95",
      descBullets: [
        "Maintained consistent academic excellence while balancing research, internships, and extracurricular activities.",
        "Completed diverse projects including educational platforms, e-commerce solutions, and AI-based applications recognized in national hackathons.",
        "Acquired expertise in advanced computing concepts, spanning programming, AI/ML, cloud computing, and software engineering."
      ]
    },
    {
      schoolName: "Shri Bhagubhai Mafatlal Polytechnic",
      logo: require("./assets/images/sbmp.jpeg"),
      subHeader: "Diploma in Information Technology",
      duration: "June 2020 - June 2023",
      desc: "CGPA : 9.45",
      descBullets: [
        "Achieved comprehensive knowledge in core computing subjects, laying a strong foundation in software development and systems design.",
        "Led the final-year project focused on a drone control system utilizing hand gesture recognition and real-time object detection.",
        "Excelled in both theoretical concepts and practical applications, emphasizing problem-solving and innovation in project work."
      ]
    }
  ]
};

const techStack = {
  viewSkillBars: true,
  experience: [
    {
      Stack: "Frontend/Design",
      progressPercentage: "90%"
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false
};

const workExperiences = {
  display: true,
  experience: [
    {
      role: "Research Intern",
      company: "IIT Patna",
      companylogo: require("./assets/images/iitpatna.png"),
      date: "December 2023 - Present",
      descBullets: [
        "Scrapped and annotated peer reviews from electric venues such as ICLR, Open Review curating our dataset of 20000+ reviews for the research purpose. Performed citations in papers and got trained by an NLP expert.",
        "Developed multi-task models with 6 ablation variants, comprising SciBERT, BERT-Base, and Bi-LSTM, connected to multiple attention blocks to determine aspect categories and their sentiments for any given review"
      ]
    },
    {
      role: "Full Stack AI Intern",
      company: "Infiheal",
      companylogo: require("./assets/images/infiheal.jpeg"),
      date: "December 2023 - Present",
      descBullets: [
        "Leveraged advanced AI technologies to enhance machine learning model accuracy by 15% and developed classification models, resulting in a 20% increase in data processing efficiency, significantly improving decision-making",
        "Utilized AWS services to improve system reliability by 25%, reduce infrastructure costs by 30%, and automate workflows, achieving a 40% reduction in processing time and a 20% increase in operational efficiency"
      ]
    },
    {
      role: "Data Analyst",
      company: "Illinois Institute of Technology, Chicago",
      companylogo: require("./assets/images/illinois.png"),
      date: "July 2022 - August 2022",
      descBullets: [
        "Upgraded and managed data dashboards resulting in a 30% increase in data accessibility and understanding",
        "Applied advanced statistical methods to interpret data, leading to a 20% improvement in decision-making accuracy"
      ]
    },
    {
      role: "Full Stack Developer",
      company: "Skillsvista",
      companylogo: require("./assets/images/skillsvista.png"),
      date: "July 2022 - September 2022",
      descBullets: [
        "Architected responsive web components, resulting in a 30% improvement in page load times and a 20% increase",
        "Engineered RESTful APIs and models achieving 99.9% uptime and reducing API response times by 40%"
      ]
    },
    {
      role: "Game Developer",
      company: "Blackstone Game Development",
      companylogo: require("./assets/images/bgd.png"),
      date: "July 2021 - August 2021",
      descBullets: [
        "Collaborated with a team of 8 developers to design and create 3 engaging game experiences",
        "Integrated 15 new features across interactive games, resulting in a 20% increase in player engagement metrics"
      ]
    },
  ]
};

const openSource = {
  showGithubProfile: "false",
  display: true
};

const bigProjects = {
  title: "Projects",
  subtitle: "Give a look at some of my projects 🤗",
  projects: [
    {
      image: require("./assets/images/insighthound.png"),
      projectName: "InsightHound",
      projectDesc: "Empowering Startups with AI-Driven Market Insights and Growth Strategies 🚀",
      footerLink: [
        {
          name: "Youtube Link",
          url: "https://www.youtube.com/watch?v=CWz2jrrN6OI"
        },
        {
          name: "Devfolio Link",
          url: "https://devfolio.co/projects/insighthounds-d4ba"
        },
        {
          name: "GitHub Link",
          url: "https://github.com/MihirRajeshPanchal/DjDawgs_100X_Buildathon"
        },
        {
          name: "Website Link",
          url: "https://insight-hound.vercel.app/"
        }
      ]
    },
    {
      image: require("./assets/images/citespy.png"),
      projectName: "CiteSpy",
      projectDesc: "Effortless research paper search at your fingertips",
      footerLink: [
        {
          name: "GitHub Link",
          url: "https://github.com/MihirRajeshPanchal/CiteSpy"
        },
        {
          name: "Youtube Link",
          url: "https://www.youtube.com/watch?v=q69oc-11KiE"
        },
        {
          name: "Semantic Scholar",
          url: "https://www.semanticscholar.org/api-gallery/citespy"
        },
        {
          name: "Marketplace",
          url: "https://marketplace.visualstudio.com/items?itemName=MihirPanchal.citespy"
        }
      ]
    },
    {
      image: require("./assets/images/dealdex.png"),
      projectName: "Dealdex",
      projectDesc: "Streamline Your Shopping, Compare with Confidence - DealDex",
      footerLink: [
        {
          name: "Youtube Link",
          url: "https://www.youtube.com/watch?v=qobgqQfLZx4"
        },
        {
          name: "Devfolio Link",
          url: "https://devfolio.co/projects/dealdex-6044"
        },
        {
          name: "Github Link",
          url: "https://github.com/MihirRajeshPanchal/Dealdex"
        }
      ]
    },
    {
      image: require("./assets/images/thinQ.png"),
      projectName: "Thinq",
      projectDesc: "Redefine the online learning experience, integrating cutting-edge features to enhance engagement and effectiveness in virtual classrooms.",
      footerLink: [
        {
          name: "Youtube Link",
          url: "https://www.youtube.com/watch?v=93gLAg1zmVc"
        },
        {
          name: "Github Link",
          url: "https://github.com/MihirRajeshPanchal/ThinQ"
        }
      ]
    },
    {
      image: require("./assets/images/blitzai.png"),
      projectName: "Blitz AI",
      projectDesc: "Unleashing Creativity, Elevating Efficiency: Your AI-Powered Companion in Content Creation Excellence.",
      footerLink: [
        {
          name: "Youtube Link",
          url: "https://www.youtube.com/watch?v=3U2L2knQWGQ"
        },
        {
          name: "Devfolio Link",
          url: "https://devfolio.co/projects/blitz-ai-6095"
        },
        {
          name: "Github Link",
          url: "https://github.com/MihirRajeshPanchal/BlitzAI"
        }
      ]
    },
    {
      image: require("./assets/images/nutrino.png"),
      projectName: "Nutrino",
      projectDesc: "Where Wellness Meets Wisdom - Diet and Nutrition App",
      footerLink: [
        {
          name: "Youtube Link",
          url: "https://www.youtube.com/watch?v=7HsiHGEhoQU"
        },
        {
          name: "Devfolio Link",
          url: "https://devfolio.co/projects/nutrino-cd8a"
        },
        {
          name: "Github Link",
          url: "https://github.com/MihirRajeshPanchal/Nutrino"
        }
      ]
    },
    {
      image: require("./assets/images/commentcleaner.png"),
      projectName: "CommentCleaner",
      projectDesc: "VS Code Extension to clean up comments in your code",
      footerLink: [
        {
          name: "GitHub Link",
          url: "https://github.com/MihirRajeshPanchal/commentcleaner"
        },
        {
          name: "Youtube Link",
          url: "https://www.youtube.com/watch?v=Zbi43ByCqog"
        },
        {
          name: "Marketplace",
          url: "https://marketplace.visualstudio.com/items?itemName=MihirPanchal.commentcleaner"
        }
      ]
    },
    {
      image: require("./assets/images/codelinelogger.png"),
      projectName: "CodeLineLogger",
      projectDesc: "Versatile VS Code extension that enables seamless line logging for multiple programming languages",
      footerLink: [
        {
          name: "Marketplace",
          url: "https://marketplace.visualstudio.com/items?itemName=MihirPanchal.codelinelogger"
        },
        {
          name: "Youtube Link",
          url: "https://www.youtube.com/watch?v=mvAKkogJ9TI"
        },
        {
          name: "Github Link",
          url: "https://github.com/MihirRajeshPanchal/codelinelogger"
        },
        {
          name: "Website Link",
          url: "https://codelinelogger.netlify.app/"
        }
      ]
    },
    {
      image: require("./assets/images/rypjaws.png"),
      projectName: "Rypjaws",
      projectDesc: "Vibrant grey theme for an enhanced VS Code coding experience",
      footerLink: [
        {
          name: "Marketplace",
          url: "https://marketplace.visualstudio.com/items?itemName=MihirPanchal.rypjaws"
        },
        {
          name: "Github Link",
          url: "https://github.com/MihirRajeshPanchal/rypjaws"
        },
        {
          name: "Website Link",
          url: "https://rypjaws.netlify.app/"
        }
      ]
    },
    {
      image: require("./assets/images/railtail.png"),
      projectName: "Railtail",
      projectDesc: "AI-powered CCTV networks to monitor railway stations and trains in real-time",
      footerLink: [
        {
          name: "Github Link",
          url: "https://github.com/MihirRajeshPanchal/RailTail"
        }
      ]
    },
    {
      image: require("./assets/images/stamp.png"),
      projectName: "STAMP Drone",
      projectDesc: "Disaster Management Drone using AI and IoT",
      footerLink: [
        {
          name: "Github Link",
          url: "https://github.com/MihirRajeshPanchal/STAMP-Drone"
        }
      ]
    },
    {
      image: require("./assets/images/ttsvoice.png"),
      projectName: "TTSVoice",
      projectDesc: "Simple Text to Speech Library",
      footerLink: [
        {
          name: "NPM Package",
          url: "https://www.npmjs.com/package/ttsvoice"
        },
        {
          name: "PYPI Package",
          url: "https://pypi.org/project/ttsvoice/"
        },
        {
          name: "Github Link (NPM)",
          url: "https://github.com/MihirRajeshPanchal/npm-install-ttsvoice"
        },
        {
          name: "Github Link (PYPI)",
          url: "https://github.com/MihirRajeshPanchal/pip-install-ttsvoice"
        },

      ]
    },
    {
      image: require("./assets/images/tinfly.jpeg"),
      projectName: "TinFly",
      projectDesc: "Music Player for Blind using Python and libraries like Tensorflow , Speech Recognition",
      footerLink: [
        {
          name: "Github Link",
          url: "https://github.com/MihirRajeshPanchal/TinFly"
        }
      ]
    }
  ],
  display: true
};

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "LinkedIn Top Voice 2024",
      subtitle:
        "In the top 5% of quality contributors to Artificial Intelligence (AI) in the world",
      image: require("./assets/images/topvoice.png"),
      imageAlt: "Linkedin Top Voice",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.linkedin.com/in/mihirpanchal54/"
        }
      ]
    },
    {
      title: "Winter Hacks 2024 Winner",
      subtitle:
        "Winner of Winter Hacks 2024, a competition organized by Google Developers Student Club",
      image: require("./assets/images/winterhacks.png"),
      imageAlt: "Winter Hacks",
      footerLink: [
        {
          name: "View Post",
          url: "https://www.linkedin.com/posts/mihirpanchal54_hacktoberfest2023-hacktoberfest-hacktoberfestcompletion-activity-7145371011742416896-B84t?utm_source=share&utm_medium=member_desktop"
        }
      ]
    },
    {
      title: "TSEC Codeissance",
      subtitle: "Secured Top 10 in TSEC Codeissance, a national coding competition",
      image: require("./assets/images/codeissance.png"),
      imageAlt: "Codeissance",
      footerLink: [
        {
          name: "View Post",
          url: "https://github.com/MihirRajeshPanchal/RailTail"
        }
      ]
    },
    {
      title: "KJSCE Hack 6.0",
      subtitle: "Secured Top 10 in KJSCE Hack 6.0, a national coding competition",
      image: require("./assets/images/hack6.png"),
      imageAlt: "KJSCE Hack 6.0",
      footerLink: [
        {
          name: "View Post",
          url: "https://www.linkedin.com/posts/mihirpanchal54_hackathon-top10-experience-activity-6970645539264561152-50kF?utm_source=share&utm_medium=member_desktop"
        }
      ]
    }
  ],
  display: true
};

const researchSection = {
  title: emoji("Research Work 🏆 "),
  subtitle:
    "Research Work and Publications that I have been a part of",

  researchCards: [
    {
      title: "Game Machine and Algorithm towards Trends in Game States using Machine Learning and Deep Learning",
      image: require("./assets/images/ieee.png"),
      imageAlt: "IEEE",
      footerLink: [
        {
          name: "DOI",
          url: "https://ieeexplore.ieee.org/abstract/document/10112350"
        }
      ]
    },
    {
      title: "Implementation of Hybrid Programming Approach Using Python for Cross Platforms Mobile and Web Application in Cloud Environment",
      image: require("./assets/images/irjiet.png"),
      imageAlt: "IRJIET",
      footerLink: [
        {
          name: "DOI",
          url: "https://doi.org/10.47001/IRJIET/2023.708008"
        }
      ]
    },
  ],
  display: true
};


const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true",
  blogs: [
    {
      url: "https://www.geeksforgeeks.org/what-is-sagemaker-in-aws",
      title: "What is SageMaker in AWS?",
      image : require("./assets/images/gfg.png")
    },
    {
      url: "https://www.geeksforgeeks.org/everything-you-need-to-know-about-open-source-development",
      title: "Everything You Need to Know About Open Source Development",
      image : require("./assets/images/gfg.png")
    },
    {
      url: "https://www.geeksforgeeks.org/how-to-upload-json-file-to-amazon-dynamodb-using-python",
      title: "How to Upload JSON File to Amazon DynamoDB using Python?",
      image : require("./assets/images/gfg.png")
    },
    {
      url: "https://medium.com/@mihirpanchal5400/text-to-speech-by-using-ttsvoice-python-2830fb304edc",
      title: "Text to Speech by using ttsvoice - Python",
      image : require("./assets/images/medium.png")
    },
    {
      url: "https://www.geeksforgeeks.org/text-to-speech-by-using-ttsvoice-python/",
      title: "Text to Speech by using ttsvoice - Python",
      image : require("./assets/images/gfg.png")
    },
    {
      url: "https://medium.com/@mihirpanchal5400/creating-chatgpt-clone-in-python-f4efac390fed",
      title: "Creating ChatGPT Clone in Python",
      image : require("./assets/images/medium.png")
    },
    {
      url: "https://geeksforgeeks.org/creating-chatgpt-clone-in-python/",
      title: "Creating ChatGPT Clone in Python",
      image : require("./assets/images/gfg.png")
    },
    {
      url: "https://medium.com/@mihirpanchal5400/difference-between-jpeg-and-mpeg-80b0513c0b40",
      title: "Difference Between JPEG and MPEG",
      image : require("./assets/images/medium.png")
    },
    {
      url: "https://geeksforgeeks.org/difference-between-jpeg-and-mpeg/",
      title: "Difference Between JPEG and MPEG",
      image : require("./assets/images/gfg.png")
    },
    {
      url: "https://medium.com/@mihirpanchal5400/difference-between-digital-audio-and-midi-77b645575f4b",
      title: "Difference Between Digital Audio and MIDI",
      image : require("./assets/images/medium.png")
    },
    {
      url: "https://www.geeksforgeeks.org/difference-between-digital-audio-and-midi/",
      title: "Difference Between Digital Audio and MIDI",
      image : require("./assets/images/gfg.png")
    },
    {
      url: "https://www.geeksforgeeks.org/hybrid-programming-using-python-and-dart/",
      title: "Hybrid Programming using Python and Dart",
      image : require("./assets/images/gfg.png")
    },
  ],
  display: true
};

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Why Research Papers? A Comprehensive Guide",
      subtitle: "DJSACM Research Seminar",
      slides_url: "https://github.com/DJSACM-Research",
      event_url: "https://www.youtube.com/watch?v=AcKInwZMjlc"
    },
    {
      title: "Leveraging Boto3: Pythonic access to S3 and SNS on AWS",
      subtitle: "Mumpy June Meetup 2024",
      slides_url: "https://github.com/MihirRajeshPanchal/aws-python-workshop",
      event_url: "https://lu.ma/9y92vfhb?tk=cUB7Ey"
    },
    {
      title: "From Code to Community: Publishing PyPI Packages in the Open Source World",
      subtitle: "FOSS United June Meetup 2024",
      slides_url: "https://github.com/MihirRajeshPanchal/pypi-foss",
      event_url: "https://fossunited.org/events/mum-jun24-archive-a507c69e"
    },
    {
      title: "Unlocking the Power of Computer Vision with Mediapipe",
      subtitle: "Mumpy March Meetup 2024",
      slides_url: "https://github.com/MihirRajeshPanchal/Mediapipe-Workshop",
      event_url: "https://lu.ma/kvk79yks?tk=uIl4RC"
    },
    {
      title: "Mastering Python: A Hands-on Workshop for Developers",
      subtitle: "NMIMS Navi Mumbai STME Workshop",
      slides_url: "https://github.com/MihirRajeshPanchal/nmims-stme",
      event_url: "https://www.instagram.com/p/C_06mZ7zv5e/"
    }
  ],
  display: true
};

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: true
};

const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  display: true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91 9833371632",
  email_address: "mihirpanchal5400@gmail.com"
};

const twitterDetails = {
  userName: "mihirpanchal54", 
  display: true
};

const isHireable = false;

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  researchSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
